<template>
  <div class="pricing">
    <div class="row margin-0 justify-content-center">
      <div class="col-md-4 pad-top-40"><br>
        <h1 style="line-height: 1.4em;">Now <br> It's easy for you <br> to sell your car</h1>
        <p class="txt-grey">Stop searching for people to sell your care. We help you to find the customers and establish communication directly to your mobile.</p><br>
        <button class="btn btn-success">Start Posting a free Ad</button>
      </div>
      <div class="col-md-6">
        <img src="https://di-uploads-pod2.dealerinspire.com/gillchevy/uploads/2015/10/2016-Corvette-Z06_OnWhite.png" alt="">
      </div>
      <div class="col-md-10 pad-top-20">
        <h3 class="text-center">How we help?</h3><br>
        <ul class="list-inline">
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon>We are using the best ad optimization for showing your ad to right customer and follow up with perfect communicational methods.</li>
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon>One of the best web pattren image placement.</li>
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> We are one of the best free classifieds provider in india.</li>
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> Easy ad featuring with low budget is a wonderful opportunity for business users.</li>
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> We have 24 X 7 customer support to solve your issues and support customer to add content.</li>
          <li class="pad-5"><ion-icon class="text-warning" name="star"></ion-icon> Unilimited ads posting is the one of the best feature in Ebechana. Most of the people are interested to list their products online.</li>
        </ul><br><br>
        <div class="text-center margin-bottom-40">
          <button class="btn btn-success">Start Posting a free Ad</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      msg: null
    }
  }
}
</script>
<style lang="stylus" scoped>
.pricing
  padding-top 10vh
  min-height 100vh
  background-color #ffffff
</style>
